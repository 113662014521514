import { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { FilledButton, FullButton, TextBox } from "../styled/input/Input";
import CircularLoader from "../styled/loaders/CircularLoader";
import { db, storage } from "../firebase/firebase";
import { deleteField, doc, setDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { context } from "../context/context";
import Toast from "../hooks/Toast";

const ShowSeedPhraseModal = ({ open, selected }) => {
  const { showPhrase, setShowPhrase } = open;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(context);

  const { id } = userData;

  // const [phrases, setPhrases] = useState([]);
  // const [currPhrase, setCurrPhrase] = useState(null);

  // useEffect(() => {
  //   if (userData) {
  //     const { phrases } = userData;
  //     setCurrPhrase(phrases[selected]?.phrase);
  //     setPhrases(phrases);
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 360);
  //   }
  // }, [userData]);

  // const [isSaving, setIsSaving] = useState(false);

  //   phrases.

  // async function handleSave() {
  //   setIsSaving(true);
  //   const profile = doc(db, "users", id);
  //   const key = `phrases.${selected}`;
  //   await updateDoc(profile, {
  //     [key]: {
  //       name: selected,
  //       phrase: currPhrase,
  //     },
  //   })
  //     .then(() => {
  //       setIsSaving(false);
  //       setToastType("success");
  //       setTimeout(() => {
  //         setShowPhrase(false);
  //       }, 270);
  //       setToastMessage("Updated successfully");
  //       setOpenToast(true);
  //     })
  //     .catch((error) => {
  //       setIsSaving(false);
  //       setToastType("error");
  //       setToastMessage("Failed to update. Please try again later");
  //       setOpenToast(true);
  //     });
  // }

  // function handlePhrase(e) {
  //   const value = e.target.value;

  //   if (value) {
  //     setCurrPhrase(value);
  //   } else {
  //     setCurrPhrase(currPhrase);
  //   }
  // }

  const [isSaving, setIsSaving] = useState(false);

  function handleSave() {
    setIsSaving(true);

    setTimeout(() => {
      setToastType("success");
      setToastMessage("Signed in successfully");
      setOpenToast(true);
      setIsSaving(false);
    }, 1300);

    setTimeout(() => {
      setShowPhrase(false);
    }, 2000);
  }

  return (
    <Modal
      open={showPhrase}
      onClose={() => setShowPhrase(false)}
      style={{
        display: "flex",
        placeContent: "center",
        zIndex: "10001",
        padding: "12px",
      }}
    >
      <>
        {openToast && (
          <Toast
            open={{ openToast, setOpenToast }}
            message={toastMessage}
            type={toastType}
          />
        )}

        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Signature requested</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowPhrase(!showPhrase)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <div className="deposit_bottom">
                <div
                  style={{
                    margin: "0 auto",
                    maxWidth: "max-content",
                    marginTop: "24px",
                  }}
                >
                  <img
                    src={`./assets/wallets/${String(
                      selected
                    ).toLowerCase()}.svg`}
                    alt=""
                    style={{ height: "120px", width: "auto" }}
                  />
                </div>
                {/* <TextBox className="scrollbar-hide">
                  <label htmlFor="address">Wallet:</label>
                  <br />
                  <input
                    type="text"
                    defaultValue={selected}
                    onChange={handleAddress}
                    disabled
                  />
                </TextBox> */}

                <br />

                <div
                  style={{
                    marginTop: "32px",
                    fontSize: "14px",
                    padding: "10px 16px",
                    border: "1px solid #222739",
                    borderRadius: "12px",
                    color: "#d4dcf7",
                    lineHeight: "24px",
                    fontWeight: "500",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>Message:</p>
                  <br />
                  <p>Welcome to {selected} </p>
                  <br />
                  <p>
                    Click to sign in and accept {selected} Terms of Service and
                    Privacy Policy
                  </p>
                  <br />
                  <p>
                    This request will not trigger a blockchain transaction ot
                    cost any gas fees
                  </p>
                </div>
              </div>
            </div>

            <br />
            <br />

            <div
              className="buttons"
              style={{
                display: "flex",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <FullButton
                role="button"
                onClick={() => handleSave()}
                className={isSaving && "disabled"}
                disabled={isSaving}
              >
                {isSaving ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                  </div>
                ) : (
                  <p>Sign in</p>
                )}
              </FullButton>
            </div>
          </div>
        </ModalStandard>
      </>
    </Modal>
  );
};

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  p.label {
    font-weight: 600;
    font-size: 14px;
    color: #bac2de;
    margin-bottom: 8px;
  }

  .bottom {
    margin-top: 32px;
  }

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    margin-top: 32px;
  }

  .bottom button:hover {
    background-color: #ff3344;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    height: fit-content;
    max-height: 90vh;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow-y: scroll;

    .bottom button {
      margin: 0;
    }

    .top {
      margin-bottom: 52px;
    }

    .bottom {
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 12px 24px;
      height: fit-content;
      background-color: #151823;
      z-index: 999;
      border: none;
      outline: none;
      /* display: none; */
    }
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }
`;

export default ShowSeedPhraseModal;
